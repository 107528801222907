/*p-tabView*/
p-tabView {
  width: 100%;
}

.ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header {
  padding: 0px;
  border: none;
  border-radius: 0px;
}

.ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li {
  margin-right: 0;
  margin-bottom: 0;
  border: none;
  background-color: #fff !important;
  color: #333333;
  cursor: pointer;
  font-size: 24px;
}

.ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li.ui-tabview-selected {
  border-bottom: 3px solid #00aeef;
}

.ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li > a {
  font-size: 16px;
  font-weight: bold;
  color: rgba(51, 51, 51, 0.6);
  padding: 36px 15px 21px;
  margin-top: 10px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

.ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li.ui-state-active > a {
  background-color: #f8f8f8;
  color: #333333;
}

div.ui-tabview-panel.ui-widget-content,
.ui-tabview.ui-widget.ui-widget-content.ui-corner-all.ui-tabview-top {
  padding: 0;
}

@media screen and (max-width: 991px) {
  .ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li {
    max-height: 50px;
    width: 50%;
  }

  .ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li > a {
    padding: 17px 10px;
    font-size: 12px;
    margin-top: 0px;
  }
}
