.carousel-block .ui-carousel {
  border: none;
  background: none !important;
}

.carousel-block .ui-carousel .ui-carousel-header {
  height: 0px;
  background: none;
  border: none;
}

.carousel-block .ui-carousel .ui-carousel-viewport .ui-carousel-item {
  border: none;
  padding: 20px;
  background: none !important;
}

.carousel-block .ui-carousel .ui-carousel-viewport .ui-carousel-item .ui-grid {
  text-align: center;
}

.carousel-block .ui-carousel .fa-arrow-circle-left:before {
  content: "\F104";
}

.carousel-block .ui-carousel .fa-arrow-circle-right:before {
  content: "\F105";
}

.carousel-block .ui-carousel .fa-arrow-circle-right,
.carousel-block .ui-carousel .fa-arrow-circle-left {
  position: absolute;
  top: 45px;
  font-size: 80px;
  z-index: 1000;
}

.carousel-block .ui-carousel .fa-arrow-circle-right {
  right: -13px;
}

.carousel-block .ui-carousel .fa-arrow-circle-left {
  left: -13px;
}

.carousel-block .ui-carousel .ui-button.ui-togglebutton {
  margin-top: 10px;
}

.carousel-block .ui-carousel .ui-button.ui-togglebutton:focus,
.carousel-block .ui-carousel .ui-button.ui-togglebutton.ui-state-focus {
  outline: none !important;
  box-shadow: none !important;
}

.carousel-block .ui-carousel .ui-carousel-page-links,
.carousel-block .ui-carousel .ui-carousel-mobiledropdown,
.carousel-block .ui-carousel .ui-carousel-dropdown,
.ui-carousel-dots-container {
  display: none !important;
}
