/*p-panel*/
p-panel .ui-panel.ui-widget {
  padding: 0;
  border: none;
  border-radius: 6px;
  -webkit-box-shadow: 0px 4px 14px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 4px 14px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 14px 0 rgba(0, 0, 0, 0.08);
}

p-panel .ui-panel .ui-panel-titlebar {
  border-radius: 6px;
  background: #fff;
  padding: 20px 43px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  position: relative;
  height: 60px;
}

p-panel .ui-panel .ui-panel-titlebar span {
  line-height: 20px;
  float: left;
}

p-panel .ui-panel .ui-panel-content-wrapper,
p-panel .ui-panel .ui-panel-content-wrapper .container {
  padding: 0;
  border-radius: 0 0 6px 6px;
}

p-panel .ui-panel .ui-panel-content-wrapper .ui-panel-content {
  border-top: 1px solid #d9dbe9;
  padding: 2.5% 3.5% 3.2%;
  position: relative;
}


a.ui-panel-titlebar-icon.ui-panel-titlebar-toggler.ui-corner-all.ui-state-default {
  border: none;
}

p-panel .ui-panel .ui-panel-titlebar a.ui-panel-titlebar-icon.ui-panel-titlebar-toggler,
a.ui-panel-titlebar-icon.ui-panel-titlebar-toggler:hover {
  color: #bbbbbb;
  background: rgba(0, 0, 0, 0.0);
  font-size: 26px;
  width: 100%;
  position: absolute;
  height: 100%;
  margin: 0;
  left: 0;
  top: 0;
}

a.ui-panel-titlebar-icon.ui-panel-titlebar-toggler span.fa {
  float: right;
  top: 28px;
  margin-right: 22px;
}

a.ui-panel-titlebar-icon.ui-panel-titlebar-toggler span.fa.fa-fw.fa-plus:before {
  content: "\f107";
}

a.ui-panel-titlebar-icon.ui-panel-titlebar-toggler span.fa.fa-fw.fa-minus:before {
  content: "\f106";
}

@media screen and (max-width: 575px) {
  a.ui-panel-titlebar-icon.ui-panel-titlebar-toggler span.fa {
    margin-right: 8px;
  }
}

.pi-plus, .pi-minus {
  background: url("../../img/arror.svg") center no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 30px;
  top: 23px;

}

.pi-plus {
  transform: rotate(180deg);
}

.pi-plus:before, .pi-minus:before {
  content: none !important;
}

.ui-panel-content-wrapper {
  background: #fff;
}

.post-header {
  margin-top: -7px;
  display: block;
}
