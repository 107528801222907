/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&subset=latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap");
@import url("./normalize.css");

/* MAIN */
body {
  font-size: 16px;
  font-family: Roboto, sans-serif !important;
  background-color: #f6f7f9;
}

input {
  border: 1px solid #d9dbe9;
  border-radius: 4px;
  height: 40px;
  padding: 12px;
}

.wrong-input > input {
  border-color: #ff495a;
}

.wrong-input > p {
  color: #ff495a;
  font-size: 12px;
  margin-top: 6px;
}

.panel-wrapper {
  margin-bottom: 20px;
}

button {
  cursor: pointer;
}

/*for basic-info block on affiliate-office and account pages*/
.basic-info-block {
  margin-bottom: 54px;
}

.details-title {
  color: #aaaaaa;
  font-size: 12px;
  line-height: 13px;
}

.details {
  font-size: 24px;
  line-height: 26px;
  margin: 10px 0 25px;
}

div a.anchor-link {
  font-size: 12px;
}

/*for panel content*/

.panel-content-title,
th span.ui-column-title {
  color: #aaaaaa;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  height: 26px;
  vertical-align: top;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .panel-content-title,
  th span.ui-column-title {
    margin-bottom: 0;
  }

  .basic-info-block {
    margin-bottom: 10px;
  }
}

th span.ui-column-title {
  position: absolute;
  top: 23px;
}

.edit-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
}

.submit-btn,
.cancel-btn {
  background-color: #dddddd;
  border: none;
  border-radius: 4px;
  color: #4a4a4a;
  cursor: pointer;
  font-size: 18px;
  height: 40px;
  line-height: 13px;
  outline: 0 !important;
  padding: 0;
  width: 100%;
}

.submit-btn {
  color: #fff;
}

.total-line {
  font-weight: 600;
}

.panel-block {
  font-size: 20px;
}

.panel-block .panel-label {
  line-height: 1;
  padding-bottom: 5px;
  padding-top: 12px;
}

.panel-block div.panel-input {
  font-size: 18px;
  height: 42px;
  max-width: 160px;
  padding: 0 0 0 10px;
}

@media screen and (max-width: 575px) {
  .panel-block div.panel-input {
    margin-top: 10px;
  }
}

.panel-table-block {
  margin-top: 12px;
}

.videos-wrapper .video-card .player .sidedock {
  display: none !important;
}

.common-input {
  width: 260px;
  border: 1px solid #d9dbe9;
  border-radius: 4px;
  height: 40px;
  padding: 12px;
}

textarea.dialog-textarea {
  border: 1px solid rgb(217, 219, 233);
  border-radius: 4px;
  margin-bottom: 25px;
  min-height: 120px;
  padding: 12px;
  resize: none;
  width: 100%;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.form-container .edit-mode-text {
  font-weight: 600;
  margin-bottom: 25px;
}

.pointer {
  cursor: pointer;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.app-cvv-help-wrapper app-cvv-help .ui-overlaypanel {
  position: absolute;
  top: -355px !important;
  left: -175px !important;
  color: #999999;
  min-width: 320px;
}

.footer-fixed {
  width: 100%;
  position: absolute;
  background-color: #fff;
  bottom: 0;
  left: 0;
  text-align: right;
  padding: 30px;
}

.footer-fixed > button {
  width: 160px;
}

.disabled {
  opacity: 0.5;
}

.select-btn {
  min-width: 57px;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
}

.error-message {
  width: 90%;
  font-size: 12px;
  color: #ff0000;
}

.credit-error-message {
  position: absolute;
  top: 90px;
  color: red;
}

/* address management styles */
.modal-wrapper .ui-dialog-content,
.address-list-content .ui-dialog-content,
.payment-form-content .ui-dialog-content {
  background-color: #f6f7f9 !important;
}

.modal-wrapper.address-form .ui-dialog-content {
  background-color: #fff !important;
  height: 675px;
  overflow-y: hidden;
}

.address-item-block {
  background-color: #fff;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.08);
  border: none;
  position: relative;
  min-height: 200px;
  border-radius: 5px;
  padding: 15px 25px 25px 17px;
  margin: 20px 0 0 0;
  font-size: 14px;
}

.address-list-block {
  margin: 0;
  overflow-x: hidden;
  color: #757575;
}

.add-address-btn,
.add-payment-btn {
  max-width: 180px;
  padding: 10px 28px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
}

.add-payment-btn .fa.fa-plus,
.add-address-btn .fa.fa-plus {
  margin-right: 5px;
}

.default-address {
  display: inline-block;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: bold;
  border: 1px solid #acacac;
  border-radius: 4px;
  padding: 2px 7px;
  width: max-content;
  margin-left: 15px;
  margin-bottom: 10px;
}

.edit-address-btn,
.remove-address-btn {
  display: inline-block;
  float: right;
  cursor: pointer;
  font-size: 20px;
}

.edit-address-btn i,
.remove-address-btn i {
  vertical-align: super;
}

.remove-address-btn {
  margin-left: 15px;
}

.address-name {
  font-weight: bold;
  margin-bottom: 10px;
  padding-right: 0;
}

.select-toggle-btn {
  position: absolute;
  bottom: 5px;
  margin-left: -5px;
}

.address-form-wrapper input,
.wizard-step-content input {
  font-size: 15px;
  padding: 25px !important;
  background-color: #f5f8f8;
  color: #6e6e6f;
}

.address-form-wrapper input::placeholder,
.wizard-step-content input::placeholder {
  color: #aeaeae !important;
}

.address-form-wrapper .toggle-buttons-block > span,
.wizard-step-content .toggle-buttons-block > span {
  margin-right: 35px;
  color: #898989;
}

.address-form-wrapper .address-form-btn-block,
.wizard-step-content .address-form-btn-block {
  margin-top: 20px;
}

.address-form-wrapper .address-form-btn-block button,
.wizard-step-content .address-form-btn-block button {
  height: 50px;
}

.readonly-input {
  background-color: #e8ebed;
  color: #b8bbbb;
}

.form-control:focus {
  border-color: #d6d6d6 !important;
}

.no-padding {
  padding: 0;
}

.add-btn-wrapper {
  position: absolute;
  top: 30px;
  right: 30px;
}

.no-display {
  display: none;
}

@media screen and (max-width: 500px) {
  .add-address-btn {
    top: 70px;
  }
}

.modal-wrapper p-dialog .ui-dialog.ui-widget .ui-dialog-titlebar,
.modal-wrapper .ui-dialog-content {
  background-color: #fff !important;
}
