/* p-dropdown */
p-dropdown .p-dropdown {
  color: #333333;
  height: 40px;
  width: 100% !important;
}

p-dropdown .p-dropdown .p-dropdown-panel {
  width: 100% !important;
}

p-dropdown .p-dropdown .p-dropdown-list {
  padding: 0;
}

p-dropdown .p-dropdown .p-dropdown-list .p-dropdown-item {
  padding: 5px;
}

p-dropdown .p-dropdown .ui-state-highlight {
  background-color: #d9dbe9 !important;

}

p-dropdown .p-dropdown .p-dropdown-trigger {
  border-left: 0;
  max-height: 30px;
  margin-right: 1px;
}

p-dropdown .p-dropdown .p-dropdown-label {
  max-width: 95%;
  max-height: 30px;
  margin: 1px;
  padding-top: 8px;
  padding-left: 14px;
}

p-dropdown .p-dropdown .p-dropdown-trigger .fa {
  margin-top: 12px;
}

p-dropdown .p-dropdown.ui-state-focus,
p-dropdown .p-dropdown.ui-inputtext:focus {
  box-shadow: none !important;
}

/*p-dropdown .p-dropdown.ui-state-default:hover,*/
/*p-dropdown .p-dropdown.ui-state-default.ui-state-focus {*/
/*  background-color: #fff !important;*/
/*  border: 1px solid #d9dbe9;*/
/*  border-color: #d9dbe9;*/
/*  color: #333333;*/
/*}*/

.address-form-wrapper .p-dropdown::placeholder,
.wizard-step-content .p-dropdown::placeholder {
  color: #AEAEAE !important;
}

.card-info-dropdown .p-dropdown-items.p-dropdown-list {
  max-height: 180px !important;
}

.address-form-wrapper .p-dropdown,
.wizard-step-content .p-dropdown {
  padding: 25px !important;
  background-color: #F5F8F8 !important;
  color: #919494;
}

.address-form-wrapper .p-dropdown label.p-dropdown-label,
.wizard-step-content .p-dropdown label.p-dropdown-label {
  position: absolute;
  top: 4px;
  left: 10px;
  color: #AEAEAE;
  background-color: #F5F8F8;
}

.address-form-wrapper .selected .p-dropdown label.p-dropdown-label,
.wizard-step-content .selected .p-dropdown label.p-dropdown-label {
  color: #646B71 !important;
}

/*
  .address-form-wrapper .p-dropdown label.p-dropdown-label:visited,
  .wizard-step-content .p-dropdown label.p-dropdown-label:visited {
    color: red !important;
  } */

.address-form-wrapper p-dropdown .p-dropdown.ui-state-default:hover,
.address-form-wrapper p-dropdown .p-dropdown.ui-state-default:active,
.address-form-wrapper p-dropdown .p-dropdown.ui-state-default:focus,
.address-form-wrapper p-dropdown .p-dropdown.ui-state-focus,
.address-form-wrapper .p-dropdown .p-dropdown-items.p-dropdown-list,
.wizard-step-content p-dropdown .p-dropdown.ui-state-default:hover,
.wizard-step-content p-dropdown .p-dropdown.ui-state-default:active,
.wizard-step-content p-dropdown .p-dropdown.ui-state-default:focus,
.wizard-step-content p-dropdown .p-dropdown.ui-state-focus,
.wizard-step-content .p-dropdown .p-dropdown-items.p-dropdown-list,
.p-dropdown-items-wrapper {
  background-color: #F5F8F8 !important;
}

.address-form-wrapper .p-dropdown .p-dropdown-trigger,
.wizard-step-content .p-dropdown .p-dropdown-trigger {
  background-color: #F5F8F8;
  color: #C3C3C3;
  font-size: 24px;
  margin-right: 8px;
  max-height: none;
}

.address-form-wrapper .p-dropdown-trigger .fa-caret-down:before,
.wizard-step-content .p-dropdown-trigger .fa-caret-down:before {
  content: "\f107";
}

.address-form-wrapper .p-dropdown .p-dropdown-list .p-dropdown-item,
.wizard-step-content .p-dropdown .p-dropdown-list .p-dropdown-item {
  margin: 0 15px;
  padding: 15px 10px;
  background-color: #F5F8F8 !important;
  color: #AEAEAE !important;
  border-bottom: 1px dashed #C3C3C3;
}

.address-form-wrapper .p-dropdown .p-dropdown-list .p-dropdown-item:last-child,
.wizard-step-content .p-dropdown .p-dropdown-list .p-dropdown-item:last-child {
  border-bottom: none;
}

.wizard-step-content .p-dropdown .p-dropdown-list .p-dropdown-item:hover,
.address-form-wrapper .p-dropdown .p-dropdown-list .p-dropdown-item:hover {
  background-color: #dddddd !important;
  color: #fff !important;
  margin: 0;
  padding-left: 25px;
}
