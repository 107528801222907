body.theme-site-id-2 .logo {
  background: #fff url(./../../img/myohealth-logo_430x51.png) left no-repeat;
}

body.theme-site-id-2 .nav-btn {
  color: #205D9F !important;
}

body.theme-site-id-2 .text-color,
body.theme-site-id-2 .contacts-customers-wrapper p-dataTable .ui-datatable th {
  color: #00aeef !important;
}

body.theme-site-id-2 .ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li.ui-tabview-selected {
  border-bottom: 3px solid #00aeef;
}

body.theme-site-id-2 div.ui-paginator-bottom.ui-paginator.ui-widget.ui-widget-header span a.ui-state-active,
body.theme-site-id-2 .bg-color {
  background-color: #00aeef !important;
}

body.theme-site-id-2 div.ui-paginator.ui-widget.ui-widget-header span a.ui-state-active,
body.theme-site-id-2 .ui-button.ui-togglebutton.ui-state-active {
  background-color: #00aeef !important;
  border-color: #00aeef !important;
}

body.theme-site-id-2 .border-color {
  border: 2px solid #00aeef !important;
}

body.theme-site-id-2 .content-footer, body.theme-site-id-2 .page-footer {
  display: none !important;
}

@media screen and (min-width: 767px) {
  body.theme-site-id-2 .logo {
    background-size: 201px 24px !important;
  }
}
