p-togglebutton {
  outline: none !important;
  width: 100%
}

.select-toggle-btn .ui-togglebutton {
  margin-right: 0px !important;
}

.payment-toggle-btn .ui-togglebutton {
  margin-right: 0px !important;
  width: 100%
}

.select-toggle-btn .ui-togglebutton.ui-button.ui-state-active,
.select-toggle-btn .ui-togglebutton.ui-button.ui-state-default,
.select-toggle-btn .p-togglebutton {
  width: 93%;
  padding: 6px;
  font-size: 14px;
  margin-left: -25px;
  max-width: calc(100% - 30px);
}

.item-details .select-toggle-btn .ui-togglebutton.ui-button.ui-state-active,
.item-details .select-toggle-btn .ui-togglebutton.ui-button.ui-state-default {
  margin-left: 0px;
}

.select-toggle-btn .ui-togglebutton.ui-button.ui-state-default {
  background-color: #dddddd;
}

.select-toggle-btn .ui-button.ui-togglebutton.ui-state-active.ui-state-disabled {
  opacity: 1 !important;
}

.address-form-wrapper .ui-togglebutton,
.wizard-step-content .ui-togglebutton {
  font-size: 13px;
}

.address-form-wrapper .ui-togglebutton.ui-button.ui-state-default,
.wizard-step-content .ui-togglebutton.ui-button.ui-state-default {
  background: #F4F7F9;
}

.address-form-wrapper .ui-togglebutton.ui-button.ui-state-focus,
.wizard-step-content .ui-togglebutton.ui-button.ui-state-focus {
  box-shadow: none !important;
}

.address-form-wrapper .toggle-buttons-block,
.wizard-step-content .toggle-buttons-block {
  border: 1px dashed;
  border-color: #E3E2EB;
  border-radius: 5px;
  padding: 15px;
}

/* .address-form-wrapper .ui-button.ui-togglebutton,
.wizard-step-content .ui-button.ui-togglebutton {
  margin-right: 10px;
} */
