.ui-datepicker {
  background: #fff;
}

p-dialog .ui-dialog {
  border: none;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 14px 0 rgba(0, 0, 0, 0.08);
}

p-dialog .ui-dialog.ui-widget .ui-dialog-titlebar {
  background: #fff;
  color: #333333;
  font-weight: bold;
  padding: 30px 15px 10px;
  min-height: 80px;
}

p-dialog .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  background: #fff !important;
  border: none !important;
  color: #bbbbbb;
  font-size: 14px;
  margin-right: -5px;
  padding: 0;
}

p-dialog .ui-dialog.ui-widget .ui-dialog-content {
  padding: 40px 30px 50px;
}

.wizard-wrapper p-dialog .ui-dialog.ui-widget .ui-dialog-content,
.order-editing-wrapper p-dialog .ui-dialog.ui-widget .ui-dialog-content,
.modal-wrapper p-dialog .ui-dialog.ui-widget .ui-dialog-content {
  position: initial;
  padding: 0px 30px 120px;
}

.wizard-wrapper p-dialog .ui-dialog.ui-widget .ui-dialog-content > .content,
.order-editing-wrapper
  p-dialog
  .ui-dialog.ui-widget
  .ui-dialog-content
  > .content,
.modal-wrapper p-dialog .ui-dialog.ui-widget .ui-dialog-content > .content {
  overflow-x: hidden;
}

.modal-wrapper a.ui-dialog-titlebar-icon.ui-dialog-titlebar-close,
.wizard-wrapper a.ui-dialog-titlebar-icon.ui-dialog-titlebar-close,
.p-dialog-header-icons {
  background: none !important;
  position: absolute;
  top: -30px;
  right: -40px;
  font-size: 30px;
  color: #fff;
}

.modal-wrapper p-dialog .ui-dialog.ui-widget .ui-dialog-titlebar {
  background-color: #f6f7f9 !important;
  border-bottom: none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.modal-wrapper.add-edit-form-wrapper
  p-dialog
  .ui-dialog.ui-widget
  .ui-dialog-titlebar {
  background-color: #fff !important;
  padding-left: 60px;
}

.modal-wrapper p-dialog .ui-dialog.ui-widget .ui-dialog-content {
  padding: 0px 15px 40px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.add-edit-form-wrapper p-dialog .ui-dialog.ui-widget .ui-dialog-content {
  background-color: #fff !important;
}

.modal-wrapper p-dialog .header span {
  vertical-align: sub;
}

@media screen and (max-width: 767px) {
  p-dialog .footer-fixed > button {
    width: 45%;
    margin-left: 5px;
  }

  p-dialog .ui-dialog button {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 825px) {
  .modal-wrapper a.ui-dialog-titlebar-icon.ui-dialog-titlebar-close,
  .wizard-wrapper a.ui-dialog-titlebar-icon.ui-dialog-titlebar-close,
  .p-dialog-header-icons {
    right: 0px;
  }

  .p-dialog {
    width: 100% !important;
  }
}

@media screen and (max-width: 575px) {
  p-dialog .ui-dialog {
    width: 98% !important;
  }

  p-dialog .ui-dialog button {
    margin-bottom: 20px;
  }
}

/* refer-a-friend p-dialog styles */

.refer-a-friend-dialog .ui-dialog .ui-dialog-content {
  padding-top: 0px !important;
}

.refer-a-friend-dialog .ui-dialog-titlebar {
  line-height: 1;
  font-size: 14px;
  padding-left: 30px !important;
}

.refer-a-friend-dialog .ui-dialog .ui-dialog-content input {
  margin: 10px 0;
}

.refer-a-friend-dialog .ui-dialog .ui-dialog-content button {
  width: 100%;
  min-height: 35px;
  border-radius: 5px;
  color: #ffffff;
  text-transform: capitalize;
  line-height: 1.2;
  font-size: 14px;
}

.refer-a-friend-dialog .ui-dialog .ui-dialog-content button:disabled {
  cursor: not-allowed;
}

.refer-a-friend-dialog .ui-dialog .ui-dialog-content button:focus {
  outline: none;
}
