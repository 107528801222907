.ui-growl {
  z-index: 1100 !important;
  width: 100% !important;
  top: 0 !important;
  right: 0 !important;
}

.ui-growl .ui-growl-item-container {
  min-height: 70px !important;
}

.ui-growl .ui-growl-title {
  margin: 0 30px 0 10px !important;
}

.ui-growl .ui-growl-item-container .ui-growl-icon-close {
  margin-top: 10px !important;
}

.ui-growl .ui-growl-item {
  width: 60% !important;
  margin: 0 auto !important;
  padding-bottom: 0;
  min-width: 350px !important;
  font-size: 18px !important;
}
