/*for paginator*/
.paginator-wrapper {
  margin-top: 40px;
  text-align: center;
}

.paginator-wrapper p-paginator {
  display: inline-block;
}

div.ui-paginator.ui-widget.ui-widget-header {
  background: none;
  border: none;
  text-align: left;
}

div.ui-paginator.ui-widget.ui-widget-header a {
  margin-right: 10px;
  font-size: 18px;
  width: 36px;
  height: 36px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  div.ui-paginator.ui-widget.ui-widget-header a {
    margin-right: 0px;
    font-size: 12px;
    width: 24px;
    height: 24px;
  }
}
