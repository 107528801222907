/*for p-datatable*/
.compensation-info-payments-wrapper p-dataTable .ui-datatable,
.contacts-customers-wrapper p-dataTable .ui-datatable,
.contacts-prospects-wrapper p-dataTable .ui-datatable {
  margin: 0 -44px;
}

.compensation-info-payments-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-thead
  tr
  th,
.contacts-customers-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-thead
  tr
  th,
.contacts-prospects-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-thead
  tr
  th,
.product-list-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-thead
  tr
  th,
.product-list-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-thead
  tr
  th {
  background-color: #f0f0f6;
  height: 57px;
  padding: 0 43px;
  position: relative;
}

.contacts-customers-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-thead
  tr
  th
  .ui-sortable-column-icon,
.product-list-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-thead
  tr
  th
  .ui-sortable-column-icon {
  position: absolute;
  left: 15px;
  top: 20px;
}

@media screen and (max-width: 575px) {
  .compensation-info-payments-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-thead
    tr
    th,
  .contacts-customers-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-thead
    tr
    th,
  .product-list-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-thead
    tr
    th,
  .contacts-prospects-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-thead
    tr
    th {
    padding: 5px;
  }

  .contacts-customers-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-thead
    tr
    th
    .ui-sortable-column-icon,
  .product-list-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-thead
    tr
    th
    .ui-sortable-column-icon {
    display: none;
  }
}

.compensation-info-payments-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-data
  tr
  td,
.contacts-customers-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-data
  tr
  td,
.product-list-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-data
  tr
  td,
.contacts-prospects-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-data
  tr
  td {
  color: #4a4a4a;
  font-weight: 500;
  height: 57px;
  padding: 35px 43px 6px;
}

.compensation-info-payments-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-thead
  tr
  th
  .ui-column-title,
.contacts-customers-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-thead
  tr
  th
  .ui-column-title,
.product-list-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-thead
  tr
  th
  .ui-column-title,
.contacts-prospects-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-thead
  tr
  th
  .ui-column-title {
  text-transform: uppercase;
  color: #aaaaaa;
}

.compensation-info-payments-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-data
  tr
  td,
.contacts-prospects-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-data
  tr
  td,
.contacts-customers-wrapper
  p-dataTable
  .ui-datatable
  .ui-datatable-tablewrapper
  .ui-datatable-data
  tr
  td {
  border: none;
}

tbody.ui-datatable-data tr.ui-widget-content.ui-datatable-even td,
tbody.ui-datatable-data tr.ui-widget-content.ui-datatable-even,
tbody.ui-datatable-data tr.ui-widget-content.ui-datatable-odd,
tbody.ui-datatable-data tr.ui-widget-content.ui-datatable-odd td,
tr.ui-state-default th.ui-state-default.ui-unselectable-text,
tbody.ui-datatable-data {
  border-style: none;
  background-color: #fff;
  text-align: left;
}

tbody.ui-datatable-data tr {
  border: none;
}

@media screen and (max-width: 767px) {
  .compensation-info-payments-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-data
    tr
    td,
  .contacts-customers-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-data
    tr
    td,
  .product-list-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-data
    tr
    td,
  .contacts-prospects-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-data
    tr
    td {
    height: auto;
    padding: 15px 0;
  }

  .compensation-info-payments-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-data
    tr
    td
    .ui-column-title,
  .contacts-customers-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-data
    tr
    td
    .ui-column-title,
  .product-list-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-data
    tr
    td
    .ui-column-title,
  .contacts-prospects-wrapper
    p-dataTable
    .ui-datatable
    .ui-datatable-tablewrapper
    .ui-datatable-data
    tr
    td
    .ui-column-title {
    margin-bottom: 2px;
  }
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #343a40;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}
.p-datatable > tr > td {
  border-top: 1px solid rgb(216, 213, 213);
}

th.money, td.money{
  text-align: right !important;
}
td.money {
  color: rgb(4, 179, 4) !important;
}

.total-row td{
  font-weight: bold !important;
  text-align: right !important;
}
