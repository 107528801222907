body.theme-site-id-4 .logo {
  background: url(./../../img/tv-logo.png) left no-repeat;
}

body.theme-site-id-4 .nav-btn {
  color: #205D9F !important;
}

body.theme-site-id-4 .header-text {
  color: #fff !important;
}

body.theme-site-id-4 .text-color,
body.theme-site-id-4 .contacts-customers-wrapper p-dataTable .ui-datatable th {
  color: #3a92d0 !important;
}

body.theme-site-id-4 .ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li a {
  margin-top: 0px !important;
  color: #3a92d0 !important;
}

@media screen and (min-width: 1024px) {
  body.theme-site-id-4 .ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li a {
    padding: 20px 15px 19px !important;
  }
}

body.theme-site-id-4 .ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li.ui-tabview-selected a {
  background-color: #6092BE !important;
  color: #fff !important;
}

body.theme-site-id-4 .ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li.ui-tabview-selected {
  border-bottom: none;
}

body.theme-site-id-4 div.ui-paginator-bottom.ui-paginator.ui-widget.ui-widget-header span a.ui-state-active,
body.theme-site-id-4 .bg-color {
  background-color: #3a92d0 !important;
}

body.theme-site-id-4 div.ui-paginator.ui-widget.ui-widget-header span a.ui-state-active,
body.theme-site-id-4 .ui-button.ui-togglebutton.ui-state-active {
  background-color: #3a92d0 !important;
  border-color: #3a92d0 !important;
}

body.theme-site-id-4 .header-block {
  background-color: #1F5E9E !important;
}

body.theme-site-id-4 .header-block i.fa {
  display: none;
}

body.theme-site-id-4 .ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li,
body.theme-site-id-4 .ui-tabview.ui-tabview-top {
  background-color: #205D9F !important;
  color: #fff !important;
}

body.theme-site-id-4 .ui-tabview.ui-tabview-top {
  margin-top: 19px;
}

@media screen and (max-width: 1024px) {
  body.theme-site-id-4 .ui-tabview.ui-tabview-top {
    padding-bottom: 1px !important;
  }
}

@media screen and (min-width: 767px) {
  body.theme-site-id-4 .logo {
    background-size: 140px 55px !important;
  }

  body.theme-site-id-4 .ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li a {
    margin-top: 0px !important;
  }
}

.logout-block tabs-wrapper {
  max-height: 75px;
}
