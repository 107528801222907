body.theme-site-id-3 .logo {
  background: #fff url(./../../img/trivita-logo_220x110.png) left no-repeat;
  background-size: 130px 70px;
}

body.theme-site-id-3 .nav-btn {
  color: #69a13c !important;
}

body.theme-site-id-3 .text-color,
body.theme-site-id-3 .contacts-customers-wrapper p-dataTable .ui-datatable th {
  color: #69a13c !important;
}

body.theme-site-id-3 .ui-tabview ul.ui-tabview-nav.ui-helper-reset.ui-widget-header li.ui-tabview-selected {
  border-bottom: 3px solid #69a13c;
}

body.theme-site-id-3 div.ui-paginator-bottom.ui-paginator.ui-widget.ui-widget-header span a.ui-state-active,
body.theme-site-id-3 .bg-color {
  background-color: #69a13c !important;
}

body.theme-site-id-3 div.ui-paginator.ui-widget.ui-widget-header span a.ui-state-active,
body.theme-site-id-3 .ui-button.ui-togglebutton.ui-state-active {
  background-color: #69a13c !important;
  border-color: #69a13c !important;
}

body.theme-site-id-3 .border-color {
  border: 2px solid #69a13c !important;
}

body.theme-site-id-3 .content-footer, body.theme-site-id-3 .page-footer {
  display: none !important;
}

@media screen and (min-width: 500px) {
  body.theme-site-id-3 .logo {
    background-size: 130px 70px !important;
  }
}
