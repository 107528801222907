.ui-toast-message-success {
  background: #34a835;
}

.ui-toast-message-error {
  background: #e91224;
}

.ui-toast .ui-toast-summary,
.ui-toast .ui-toast-icon:before,
.ui-toast .ui-toast-close-icon:before,
.ui-toast-message-content .pi-check:before {
  color: #ffffff;
}
