@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "assets/css/newprimecssoverrides/dialog.css";

/* You can add global styles to this file, and also import other style files */
.ui-dialog-mask {
  background: rgba(0, 0, 0, 0.4);
}

.ui-confirmdialog {
  background: #fff;
}

// to hide the default sso controls
.auth-controls {
  display: none;
}

.p-panel-header{
  cursor: pointer !important;
}
